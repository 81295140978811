"use client";
import styles from "@/styles/Home.module.css";
import Link from "next/link";
import { useEffect } from "react";

export default function Home() {
  useEffect(() => {
    const initBotpress = () => {
      if (window.botpress && window.botpress.init) {
        window.botpress.init({
          composerPlaceholder: "Message RICA...",
          botConversationDescription: "Republic Central Colleges AI Assistant",
          clientId: "b8078cd1-e975-488b-b9e0-1501775e1ad8",
          hostUrl: "https://cdn.botpress.cloud/webchat/v2.2",
          messagingUrl: "https://messaging.botpress.cloud",
          theme: "prism",
          frontendVersion: "v2",
          hideWidget: false,
          showPoweredBy: false,
          enableTranscriptDownload: false,
          botName: "RICA",
        });
      }
    };

    // Add main Botpress script
    const script1 = document.createElement("script");
    script1.src = "https://cdn.botpress.cloud/webchat/v2.2/inject.js";
    script1.async = true;

    const script2 = document.createElement("script");
    script2.src =
      "https://files.bpcontent.cloud/2024/10/05/06/20241005060635-WODMJS4N.js";
    script2.async = true;

    script1.onload = () => {
      document.body.appendChild(script2);
    };

    script2.onload = () => {
      initBotpress();
      setTimeout(initBotpress, 1000);
    };

    document.body.appendChild(script1);

    return () => {
      if (script1.parentNode) script1.parentNode.removeChild(script1);
      if (script2.parentNode) script2.parentNode.removeChild(script2);
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>Hi, I'm RICA</h1>
        <div className={styles.buttons}>
          <Link href="/about">
            <button>About RICA</button>
          </Link>
        </div>
      </div>
    </div>
  );
}
